<template>
  <div
    class="d-flex align-items-center justify-content-end"
  >
    <b-button
      variant="primary"
      class="btn-sm btn-sm-block"
      :title="$can('create', modulo)
        ? `Crear ${texto}` : moduloTitle"
      @click="$can('create', modulo)
        ? $emit('processAdd')
        : ''"
      :disabled="!$can('create', modulo)"
    >
      <span class="text-nowrap">Crear {{ texto }}</span>
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import { formatos } from '@/mixins/forms'

export default {
  components: {
    BButton,
  },
  mixins: [formatos],
  props: {
    texto: {
      type: String,
      required: true,
    },
    modulo: {
      type: String,
      required: true,
    },
  },
  computed: {
    moduloTitle() {
      return this.getTitle()
    },
  },
  methods: {
    getTitle() {
      let title = this.modulo
      if (this.modulo === 'categorias') {
        title = 'categorías'
      } else if (this.modulo === 'compras') {
        title = 'OCs'
      }
      return `No tienes permisos para crear ${this.primeraMayuscula(title)}`
    },
  },
}
</script>
